.navbar {
  width: 100%;
  background-color: #220000;
  padding: 1.2rem 1.5rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  font-size: 1.5rem;
  font-family: 'Cinzel', serif;
  text-shadow: 2px 2px 4px rgba(255, 0, 100, 2.5);
}

/* Ensure the center-links are perfectly centered, independent of the social links */
.center-links {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
}

.navbar-link {
  color: white;
  text-decoration: none;
  padding: 0.4rem 0.2rem;
  transition: color 0.3s ease;
}

.navbar-link:hover {
  color: #ff0000;
  background-color: transparent;
}

/* Align social links to the right side */
.social-links {
  margin-left: auto;
  display: flex;
  gap: 0.8rem;
  padding-inline-end: 3.0rem;
}

.social-icon {
  color: white;
  transition: color 0.3s ease;
  filter: drop-shadow(2px 2px 4px rgba(255, 0, 100, 2.5));
}

.social-icon:hover {
  color: #fc0000;
  filter: drop-shadow(2px 2px 4px rgba(255, 0, 100, 2.5));
}

/* Mobile layout adjustments */
@media (max-width: 768px) {
  .navbar {
    background-color: #220000;
    flex-direction: column;
    padding: 1rem;
  }

  .center-links {
    align-items: center;
    margin-bottom: 1rem;
  }

  .social-links {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 3.5rem;
    gap: 2.2rem;
  }
}
