.contact-page {
  background-color: black;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  padding-block-end: 10rem;
}

.contact-container {
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  position: absolute;
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.contact-background-image {
  filter: blur(6px);
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-notes {
  line-height: 1.5rem;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 600px;
  padding: 0 1rem;
  align-items: center;
}

.contact-form input,
.contact-form textarea {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;
}

.contact-form button {
  padding: 0.5rem 1rem;
  background-color: #b12121;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.contact-form button:hover {
  background-color: #510000;
}

.g-recaptcha {
  margin: 1rem auto;
}

.App-footer {
  background-color: black;
  width: 100%;
  padding: 1rem 0;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo {
  width: 150px;
}

.footer-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .contact-container {
    left: 50%;
    transform: translateX(-50%);
    padding-top: 6rem;
    width: 100%;
    box-sizing: border-box;
  }

  .header-image-container {
    margin-block-start: 96px;
  }

  .contact-form {
    margin-inline-start: 1rem;
    margin-inline-end: 1rem;
    width: calc(100% - 2rem);
  }

  .contact-page {
    padding-top: 1rem;
    padding-block-end: 5rem;
  }

  .contact-background-image {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  .App-footer {
    padding: 1rem;
  }
}
