body, html {
  background-color: black;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overscroll-behavior: contain;
}

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow-x: hidden;
}

.header-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.logo-and-buttons {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.logo-and-buttons button {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 2.5);
  transition: box-shadow 0.3s, transform 0.3s;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 2.5);
}

.logo-and-buttons button:hover {
  box-shadow: 0 16px 32px rgba(0, 0, 0, 2.7);
  transform: translateY(-4px);
}

.header-logo {
  width: 100%;
  margin-block-end: -2rem;
}

.button-container {
  display: flex;
  gap: 15px;
  width: 100%;
  max-width: 65%;
  flex-wrap: wrap;
  justify-content: center;
}

.about-container {
  margin-block-start: 1rem;
  display: flex;
  gap: 15px;
  width: 100%;
  max-width: 30%;
  flex-wrap: wrap;
  justify-content: center;
}

button {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  color: white;
  border: 1px solid white;
  cursor: pointer;
  transition: background-color 0.3s;
  text-transform: uppercase;
}

.wishlist-button {
  background-color: #9e0000;
}

.wishlist-button:hover {
  background-color: #510000;
}

.trailer-button {
  background-color: black;
}

.trailer-button:hover {
  background-color: #333;
}

.about-button {
  background-color: #3C1414;
}

.about-button:hover {
  background-color: #230c0c;
}

.header-video-container {
  margin-top: 2rem;
  width: 100%;
  max-width: 60%;
  padding-bottom: 33.75%;
  position: relative;
  z-index: 2;
}

.header-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.App-content {
  margin-top: 2rem;
  max-width: 800px;
  width: 100%;
  padding: 20px;
  z-index: 3;
  /* box-shadow: 0 0 5px 2px rgb(121, 0, 0);
  animation: glow 1.5s infinite alternate; */
}

@keyframes glow-box {
  from {
    box-shadow: 0 0 4px 2px rgb(121, 0, 0);
  }
  to {
    box-shadow: 0 0 8px 2px rgb(166, 0, 0);
  }
}

.main-title {
  font-family: 'Cinzel', serif;
  color: #d0be54;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.description-container {
  max-width: 600px;
  margin: 1rem auto;
  text-align: left;
}

.feature-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  max-width: 600px;
  margin: 2rem auto;
  align-items: start;
  text-align: left;
}

.feature-grid p {
  margin: 0;
  line-height: 1.6;
}

.videos {
  display: flex;
  gap: 1.5rem;
  max-width: 600px;
  margin: 2rem auto;
  justify-content: center; /* Add this line to center the videos */
  flex-wrap: wrap; /* Add this line to ensure videos wrap within the container */
}

.videos video {
  flex: 1 1 100%; /* Ensure videos take full width of the container */
  width: 100%;
  box-shadow: 0 0 5px 2px rgb(121, 0, 0);
  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 4px 2px rgb(121, 0, 0);
  }
  to {
    box-shadow: 0 0 8px 2px rgb(166, 0, 0);
  }
}

.steam-logo-container {
  justify-content: center;
  margin-top: 1.5rem;
  padding: 0.5rem;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
}

.steam-logo-container img {
  box-shadow: none;
}

.steam-logo-container span {
  display: block;
  box-shadow: none;
}

.steam-logo {
  width: 100%;
  max-width: 150px;
}

.release-date {
  font-size: 1.6rem;
  font-family: Arial, sans-serif;
  color: white;
  margin-inline-start: 1rem;
  margin-block-start: 0.4rem;
  text-align: center;
  font-weight: bold;
}

.footer-logo-container {
  margin-block-start: 1rem;
}

@media (max-width: 768px) {
  body, html {
    justify-content: center;
  }

  .feature-grid {
    grid-template-columns: 1fr;
  }

  .feature-grid, .description-container {
    padding-inline: 1.5rem;
    text-align: center;
    box-sizing: border-box;
  }

  .header-video-container {
    padding-bottom: 56.25%;
    max-width: 100%;
  }

  .header-container {
    margin-block-start: 2rem;
    height: auto;
    justify-content: center;
  }

  .header-logo {
    width: 290%;
    margin-block-start: 3rem;
    margin-inline-start: -1rem;
    margin-block-end: 3rem;
  }

  .header-image {
    margin-block-start: 3rem;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }
  
  .logo-and-buttons {
    margin-block-start: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .App-content {
    margin-block-start: 2rem;
    text-align: center;
  }

  .button-container {
    width: 100%;
  }

  button {
    width: 100%;
  }

  .about-container {
    max-width: 65%;
  }

  .steam-logo-container {
    margin-block-start: 2rem;
    flex-direction: row;
    align-items: center;
    max-width: 70%;
  }

  .release-date {
    font-size: 1rem;
  }

  .videos {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .main-title {
    font-size: 3rem;
  }
}

@media (max-width: 1180px) and (min-width: 769px) {
  .header-logo {
    width: 190%;
    margin-block-start: 0rem;
    margin-inline-start: -1rem;
    margin-block-end: 0rem;
  }

  .videos {
    flex-direction: column;
    gap: 1rem;
  }
}
